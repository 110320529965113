import React from 'react';
import { Blurhash as Blur } from 'react-blurhash';

function Blurhash(props) {
   const { width, height, hash, className } = props;

   if (hash) {
      return (
         <Blur
            className={className}
            hash={hash}
            height={height || '100%'}
            width={width || '100%'}
         />
      );
   }
   return null;
}

export default Blurhash;
